import TruncateText from '@oneflare/flarekit/lib/components/TruncateText';
import PropTypes from 'prop-types';
import { memo } from 'react';

import { useGenerateAvatarBackgroundColor, useWindowResize } from 'shared/utils/hooks';


import {
  ReviewCardStyled,
  TitleStyled,
  BodyStyled,
  DetailIconsStyled,
  IconGroupStyled,
  AvatarStyled,
  AvatarContainerStyled,
  AvatarDetailsContainerStyled,
  StarRatingStyled,
  HeadlineStyled,
  DateStyled,
  GoogleIconStyled,
  ProductReviewIconStyled
} from './styled/ReviewCard';

const ReviewCard = ({
  review: {
    externalReviewId,
    source,
    author,
    avatarUrl,
    rating,
    title,
    review,
    submittedAt
  }, googleReviewLink
}) => {
  const productReviewLink = `http://www.productreview.com.au/listings/oneflare#review-${externalReviewId}`;
  const isGoogle = source === 'google';

  const readMoreLink = isGoogle ? googleReviewLink : productReviewLink;

  const getReviewIcon = isGoogle ? <GoogleIconStyled /> : <ProductReviewIconStyled />;

  const viewportWidth = useWindowResize(0);

  return (
    <ReviewCardStyled>
      <DetailIconsStyled>
        <IconGroupStyled>
          <AvatarContainerStyled>
            <AvatarStyled
              circleAvatar={false}
              avatarText={avatarUrl ? '' : author.charAt(0)}
              avatarTextBackground={useGenerateAvatarBackgroundColor(author)}
              alt={`${author}'s Avatar`}
              imageUrl={avatarUrl}
              loading="lazy"
            />
            <AvatarDetailsContainerStyled>
              {getReviewIcon}
              <TitleStyled>{author}</TitleStyled>
            </AvatarDetailsContainerStyled>
          </AvatarContainerStyled>
        </IconGroupStyled>
      </DetailIconsStyled>
      <StarRatingStyled rating={rating} />
      {title && <HeadlineStyled>{title}</HeadlineStyled>}
      {review && (
        <BodyStyled>
          <TruncateText
            lines={viewportWidth < 768 ? 4 : 5}
          >
            <>
              <span>{review}</span>
              <a
                href={readMoreLink}
                target="_blank"
                rel="noreferrer"
              >
                Read more
              </a>
            </>
          </TruncateText>
        </BodyStyled>
      )}
      <DateStyled>{submittedAt}</DateStyled>
    </ReviewCardStyled>
  );
};

ReviewCard.propTypes = {
  googleReviewLink: PropTypes.string,
  review: PropTypes.shape({
    author: PropTypes.string,
    avatarUrl: PropTypes.string,
    externalReviewId: PropTypes.string,
    rating: PropTypes.number,
    review: PropTypes.string,
    source: PropTypes.string,
    submittedAt: PropTypes.string,
    title: PropTypes.string
  })
};

ReviewCard.defaultProps = {
  googleReviewLink: '',
  review: {
    author: '',
    avatarUrl: '',
    externalReviewId: '',
    rating: null,
    review: '',
    source: '',
    submittedAt: '',
    title: ''
  }
};

export default memo(ReviewCard);
