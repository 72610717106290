import H2 from '@oneflare/flarekit/lib/components/H2';
import Google from '@oneflare/flarekit/lib/components/logos/Google';
import ProductReview from '@oneflare/flarekit/lib/components/logos/ProductReview';
import Paragraph from '@oneflare/flarekit/lib/components/Paragraph/Paragraph';
import media from '@oneflare/flarekit/lib/libs/media';
import { styled } from 'styled-components';

export const ReviewSectionStyled = styled.section`
  display: flex;
  padding: 0 20px;
  margin-top: 80px;
  flex-direction: column;

  ${media.mdUp`
    padding: 0 0 0 40px;
  `}

  ${media.lgUp`
    padding: 0 40px;
    max-width: 1120px;
    margin: 160px auto 0 auto;
    flex-direction: row;
  `}

  ${media.xlUp`
    padding: 0;
  `}
`;

export const ReviewContainerStyled = styled.section`
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0%);
  max-height: 500px;

  ${media.lgUp`
    display: flex;
    padding: 0 0 0 40px;
    min-height: 380px;
    max-height: auto;
  `}
`;

export const ContainerRightStyled = styled.div`
  width: 100%;
  overflow: hidden;
  height: 100%;
  right: 0;

  ${media.lgUp`
    width: 55%;
    position: absolute;
  `}
`;

export const HeadingStyled = styled(H2)`
  font-size: 22px;
  font-family: ${({ theme }) => theme.font.secondaryBold};
  color: ${({ theme }) => theme.color.charcoal};
  margin-bottom: 16px;

  ${media.mdUp`
    font-size: 28px;
  `}

`;

export const SummaryStyled = styled(Paragraph)`
  font-size: 16px;
  color: ${({ theme }) => theme.color.gunMetal};
  width: 100%;
  margin: 0 0 24px;
  line-height: 1.5;

  ${media.mdUp`
    font-size: 18px;
    margin: 0 0 40px;
  `}

  ${media.lgUp`
    font-size: 18px;
    margin: 0 0 40px;
  `}
`;

export const ProductReviewLogoStyled = styled(ProductReview).attrs(() => ({
  height: 30,
  width: 130

}))`
  align-self: start;
  height: 28px;
  margin-top: 16px;
`;

export const GoogleLogoStyled = styled(Google).attrs(() => ({
  height: 30,
  width: 75
}))`
  align-self: start;
  height: 28px;
  margin-top: 16px;
  width: 50%;
`;

export const AverageRatingStyled = styled.div`
  width: 100%;

  ${media.lgUp`
    width: 400px;
  `}
`;
