import H2 from '@oneflare/flarekit/lib/components/H2';
import H6 from '@oneflare/flarekit/lib/components/H6';
import LeftArrow from '@oneflare/flarekit/lib/components/icons/LeftArrow/LeftArrow';
import RightArrow from '@oneflare/flarekit/lib/components/icons/RightArrow/RightArrow';
import OneflareGroup from '@oneflare/flarekit/lib/components/logos/OneflareGroup';
import media from '@oneflare/flarekit/lib/libs/media';
import Carousel from 'nuka-carousel';
import { styled } from 'styled-components';

export const HeadingStyled = styled(H2)`
  color: ${({ theme }) => theme.color.charcoal};
  font-family: ${({ theme }) => theme.font.secondaryBold};
  font-size: 22px;
  align-self: center;
  text-align: center;
  margin: 0;

  ${media.smUp`
    padding-bottom: 28px;
  `}
`;

export const CarouselWrapperStyled = styled.section`
  height: 335px;
  margin-top: 22px;
  width: 100%;

  .slider-control-centerleft {
    position: absolute;
    top: unset !important;
    bottom: -22% !important;
  }

  .slider-control-centerright {
    position: absolute;
    top: unset !important;
    bottom: -22% !important;
  }

  ${media.mdUp`
    height: 366px;
    margin-top: 62px;

    .slider-container{
      width: 906px;
    }

    .slider-control-centerleft {
      bottom: -16% !important;
    }

    .slider-control-centerright {
      top: unset !important;
      bottom: -16% !important;
      left: calc(100vw - 11%) !important;
    }
  `}

  ${media.lgUp`
    height: 100%;
    margin-top: 0;

    .slider-container{
      width: 1035px;
    }

    .slider-control-centerleft {
      bottom: -22% !important;
    }

    .slider-control-centerright {
      bottom: -22% !important;
      right: 54% !important;
      left: unset !important;
    }
  `}
`;

export const CarouselStyled = styled(Carousel)`
  .slide {
    margin-right: 0 !important;
  }

  ${media.mdUp`
    .slide {
      width: 100%;
      max-width: 288px;
      flex: unset !important;

      &:not(:first-child) {
        margin-right: 16px !important;
      }
    }
  `}

  ${media.lgUp`
    .slide {
      min-width: 329px;
      margin-right: 16px !important;
    }
  `}
`;

export const RightChevronStyled = styled(RightArrow).attrs(({ theme }) => ({
  fill: theme.color.gunMetal,
  width: 15,
  height: 15,
  strokeWidth: 1
}))`
  cursor: pointer;
`;

export const LeftChevronStyled = styled(LeftArrow).attrs(({ theme }) => ({
  fill: theme.color.gunMetal,
  width: 15,
  height: 15,
  strokeWidth: 1
}))`
  cursor: pointer;
`;

export const TextWrapperStyled = styled.section`
  display: flex;
  align-items: center;
`;

export const H6Styled = styled(H6)`
  margin: 0;
  padding-right: 7px;
  display: inline-block;
  color: ${({ theme }) => theme.color.silver};
  font-family: ${({ theme }) => theme.font.primaryRegular};
  font-size: 14px;
  align-self: center;
`;

export const OneflareGroupLogoStyled = styled(OneflareGroup).attrs(({ theme }) => ({
  primaryFillColor: theme.color.gunMetal,
  secondaryFillColor: theme.color.silver
}))`
  display: inline-block;
  height: 17px;
  width: 120px;
`;
