import Anchor from '@oneflare/flarekit/lib/components/Anchor';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { useWindowResize } from 'shared/utils/hooks';


import {
  CarouselWrapperStyled,
  CarouselStyled,
  LeftChevronStyled,
  RightChevronStyled
} from './styled/ReviewCarousel';

const ReviewCarousel = ({ children }) => {
  const viewportWidth = useWindowResize(0);

  const slidesToShow = useMemo(() => (viewportWidth > 767 ? 3 : 1), [viewportWidth]);

  return (
    <CarouselWrapperStyled>
      <CarouselStyled
        renderCenterLeftControls={({ previousSlide }) => (
          <Anchor onClick={previousSlide}>
            <LeftChevronStyled />
          </Anchor>
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <Anchor onClick={nextSlide}>
            <RightChevronStyled />
          </Anchor>
        )}
        renderBottomCenterControls={null}
        slidesToShow={slidesToShow}
        cellAlign="left"
        wrapAround
      >
        {children}
      </CarouselStyled>
    </CarouselWrapperStyled>
  );
};

ReviewCarousel.propTypes = {
  children: PropTypes.node.isRequired
};

export default ReviewCarousel;
