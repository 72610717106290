import Anchor from '@oneflare/flarekit/lib/components/Anchor/Anchor';
import Star from '@oneflare/flarekit/lib/components/icons/Star';
import media from '@oneflare/flarekit/lib/libs/media';
import { styled } from 'styled-components';

export const RatingBoxStyled = styled.div`
  width: 50%;
  max-width: 260px;
  display: inline-block;
  position: relative;

  ${media.mdUp`
    &:nth-child(2n){
     :before {
        content: "";
        background: ${({ theme }) => theme.color.cloud};
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        display: block;
        left: -40px
      }
    }
  `}

  ${media.lgUp`
    &:nth-child(2n){
      padding-left: 0;
    }
  `}

  ${media.xlUp`
    padding-left: 0;
  `}
`;

export const RatingHeaderStyled = styled.div`
  color: ${({ theme }) => theme.color.silver};
  font-family: ${({ theme }) => theme.font.secondaryBold};
  font-size: 11px;
  text-transform: uppercase;
`;

export const RatingWrapperStyled = styled.div`
  display: flex;
`;

export const RatingHeroStyled = styled.div`
  font-size: 30px;
  font-family: ${({ theme }) => theme.font.primaryRegular};
  font-weight: bold;
  color: ${({ theme }) => theme.color.charcoal};

  ${media.lgUp`
    font-size: 33px;
  `}
`;

export const RatingStarStyled = styled(Star).attrs(({ theme }) => ({
  kind: 'solid',
  fillStar: theme.color.tertiaryLight,
  width: 26,
  height: 26
}))`
  margin: 4px 0 0 8px;
`;

export const RatingSummaryStyled = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.color.gunMetal};
  margin-top: 8px;
  display: inline-block;
  width: 100%;
  padding-bottom: 4px;

  ${media.mdUp`
    max-width: initial;
    margin-bottom: 4px;
  `}

  ${media.lgUp`
    max-width: initial;
    margin-bottom: 4px;
    font-size: 13px;
  `}
`;

export const AnchorReviewStyled = styled(Anchor)`
  font-size: 16px;
  line-height: 1.5;

  ${media.lgUp`
    font-size: 13px;
  `}
`;
