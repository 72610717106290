import Anchor from '@oneflare/flarekit/lib/components/Anchor';
import Avatar from '@oneflare/flarekit/lib/components/Avatar';
import H3 from '@oneflare/flarekit/lib/components/H3';
import Google from '@oneflare/flarekit/lib/components/logos/Google';
import ProductReview from '@oneflare/flarekit/lib/components/logos/ProductReview';
import Paragraph from '@oneflare/flarekit/lib/components/Paragraph';
import media from '@oneflare/flarekit/lib/libs/media';
import { styled } from 'styled-components';

import StarRating from 'shared/components/StarRating';

export const AvatarStyled = styled(Avatar).attrs(() => ({
  kind: 'circle',
  avatarSize: {
    default: '48px'
  }
}))`
  margin: 0 auto 16px;
`;

export const AvatarContainerStyled = styled.div`
  display: flex;
`;

export const AvatarDetailsContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 16px;
`;

export const HeadlineStyled = styled(H3)`
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: ${({ theme }) => theme.font.primaryRegular};
  color: ${({ theme }) => theme.color.charcoal};
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DateStyled = styled(Paragraph)`
  color: ${({ theme }) => theme.color.spaceGrey};
  position: absolute;
  bottom: 24px;
  margin-bottom: 0;
`;

export const StarRatingStyled = styled(StarRating)`
  margin: -50px 0 16px 62px;

  .star {
    height: 16px !important;
    width: 16px !important;
  }
`;

export const ReviewCardStyled = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.color.tin};
  padding: 24px 20px 0;
  border-radius: 8px;
  min-height: 281px;
  max-height: 281px;

  ${media.smUp`
    box-sizing: border-box;
  `}

  ${media.mdUp`
    min-width: 288px;
    min-height: 321px;
    max-height: 321px;
    max-width: 373px;
  `}
`;

export const TitleStyled = styled(Paragraph)`
  margin: 0;
  width: 105px;
  line-height: 1.5;
  font-family: ${({ theme }) => theme.font.primaryRegular};
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.color.charcoal};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${media.lgUp`
    width: 145px;
  `}
`;

export const DetailIconsStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 16px;
`;

export const BodyStyled = styled.div`
  font-family: ${({ theme }) => theme.font.primaryRegular};
  font-size: 16px;
  line-height: 1.5;
  color: ${({ theme }) => theme.color.gunMetal};
  padding-top: 4px;

  a {
    color: ${({ theme }) => theme.color.link};
    text-decoration: none;
  }
`;

export const IconGroupStyled = styled.div`
  font-family: ${({ theme }) => theme.font.primaryRegular};
  font-size: 16px;
  color: ${({ theme }) => theme.color.gunMetal};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const ReadMoreAnchorStyled = styled(Anchor)`
  font-size: 16px;
  cursor: pointer;
  display: inline-block;
  margin-top: 4px;
`;

export const GoogleIconStyled = styled(Google).attrs({
  kind: 'G',
  width: 20,
  height: 20
})`
  margin-right: 8px;
  transform: translateY(2px);
`;

export const ProductReviewIconStyled = styled(ProductReview).attrs({
  kind: 'icon',
  width: 18,
  height: 20
})`
  margin-right: 8px;
  transform: translateY(2px);
`;

export const TextTruncateStyled = styled(Paragraph)`
  color: ${({ theme }) => theme.color.gunMetal};
  font-size: 16px;
  overflow: hidden;
  /* stylelint-disable */
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: ${({ title }) => (title ? 3 : 4)};
  -webkit-box-orient: vertical;
  /* stylelint-enable */

  ${media.lgUp`
    -webkit-line-clamp: ${({ title }) => (title ? 5 : 6)};
  `}
`;
