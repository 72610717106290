import pluralize from 'pluralize';
import PropTypes from 'prop-types';

import {
  RatingBoxStyled,
  RatingHeaderStyled,
  RatingWrapperStyled,
  RatingHeroStyled,
  RatingSummaryStyled,
  AnchorReviewStyled,
  RatingStarStyled
} from './styled/CustomerExternalRating';

const CustomerExternalRating = ({
  rating,
  ratingCount,
  ratingLink,
  ratingTitle,
  children
}) => {
  return (
    rating && (
    <RatingBoxStyled>
      <RatingHeaderStyled>
        {ratingTitle}
      </RatingHeaderStyled>
      <RatingWrapperStyled>
        <RatingHeroStyled>
          {rating}
        </RatingHeroStyled>
        <RatingStarStyled />
      </RatingWrapperStyled>
      {ratingCount && (
      <RatingSummaryStyled>
        based from
        <br />
        {ratingLink ? (
          <AnchorReviewStyled
            href={ratingLink}
            target="_blank"
          >
            {`${pluralize('review', ratingCount, true)} `}
          </AnchorReviewStyled>
        )
          : `${pluralize('review', ratingCount, true)} `}
        on
      </RatingSummaryStyled>
      )}
      {children}
    </RatingBoxStyled>
    )
  );
};
CustomerExternalRating.propTypes = {
  children: PropTypes.node.isRequired,
  rating: PropTypes.number.isRequired,
  ratingCount: PropTypes.number,
  ratingLink: PropTypes.string,
  ratingTitle: PropTypes.string
};
CustomerExternalRating.defaultProps = {
  ratingTitle: 'Avg rating',
  ratingCount: '',
  ratingLink: ''
};
export default CustomerExternalRating;
