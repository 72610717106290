import type { OperationVariables, QueryResult } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import type { FunctionComponent } from 'react';

import { DataDogRumAgent } from 'lib/datadog/initializeDatadog';
import { ONEFLARE_REVIEWS } from 'queries/shared/customerReview';
import { GOOGLE_REVIEW } from 'queries/shared/googleReview';
import { PRODUCT_REVIEW } from 'queries/shared/productReview';
import ReviewCarousel from 'shared/components/ReviewSection/components/ReviewCarousel';

import CustomerExternalRating from './components/CustomerExternalRating/CustomerExternalRating';
import ReviewCard from './components/ReviewCard';
import {
  ReviewContainerStyled,
  ContainerRightStyled,
  HeadingStyled,
  SummaryStyled,
  GoogleLogoStyled,
  ProductReviewLogoStyled,
  AverageRatingStyled,
  ReviewSectionStyled
} from './styled/ReviewSection';

type ReviewsSectionProps = {
  className?: string;
  pageTitle?: string;
  title?: string;
  summary?: string;
}

const ReviewsSection: FunctionComponent<ReviewsSectionProps> = ({
  className,
  title,
  pageTitle,
  summary
}) => {
  const googleReviewLink = 'https://www.google.com/search?q=oneflare&rlz=1C5CHFA_enAU860AU860&oq=oneflare&aqs=chrome..69i57j69i60l5.7397j1j1&sourceid=chrome&ie=UTF-8#lrd=0x6b12ae38b0ced841:0xb2b056fdea5a7ca,1';

  return (
    <ReviewSectionStyled className={className}>
      <AverageRatingStyled>
        <HeadingStyled>{title}</HeadingStyled>
        <SummaryStyled>
          {summary}
        </SummaryStyled>
        <Query query={GOOGLE_REVIEW}>
          {({ data, loading, error }: QueryResult<any, OperationVariables>) => {
            if (error) {
              DataDogRumAgent.addRumError(error, `Oneflare | ${pageTitle} Reviews Section | Google Avg Rating`);
              return null;
            }

            if (loading) return null;
            const { googlePlaceSearch } = data;

            if (!googlePlaceSearch?.rating) return null;

            return (
              <CustomerExternalRating
                rating={googlePlaceSearch.rating}
                ratingCount={googlePlaceSearch.userRatingsTotal}
                ratingLink={googleReviewLink}
              >
                <GoogleLogoStyled />
              </CustomerExternalRating>
            );
          }}
        </Query>
        <Query query={PRODUCT_REVIEW}>
          {({ data, loading, error }: QueryResult<any, OperationVariables>) => {
            if (error) {
              DataDogRumAgent.addRumError(error, `Oneflare | ${pageTitle} Reviews Section | Product Review Avg Rating`);
              return null;
            }

            if (loading) return null;
            const { productReview } = data;

            if (!productReview?.rating) return null;

            return (
              <CustomerExternalRating
                rating={productReview.rating}
                ratingCount={productReview.numberOfReviews}
                ratingLink="https://www.productreview.com.au/listings/oneflare"
              >
                <ProductReviewLogoStyled />
              </CustomerExternalRating>
            );
          }}
        </Query>
      </AverageRatingStyled>
      <Query query={ONEFLARE_REVIEWS}>
        {({ data, loading, error }: QueryResult<any, OperationVariables>) => {
          const oneflareReviews = data?.oneflareReviews || [];

          if (error) {
            DataDogRumAgent.addRumError(error, `Oneflare | ${pageTitle} Reviews Section | Oneflare Reviews`);
            return null;
          }

          if (loading || !(oneflareReviews?.length)) return null;

          return (
            <ReviewContainerStyled>
              <ContainerRightStyled>
                <ReviewCarousel>
                  {oneflareReviews.map((review) => (
                    <ReviewCard
                      key={review.externalReviewId}
                      review={review}
                      googleReviewLink={googleReviewLink}
                    />
                  ))}
                </ReviewCarousel>
              </ContainerRightStyled>
            </ReviewContainerStyled>
          );
        }}
      </Query>
    </ReviewSectionStyled>
  );
};

ReviewsSection.defaultProps = {
  className: 'e2e-hide',
  pageTitle: 'Home Page',
  title: 'What\'s all the fuss about?',
  summary: 'Every day, thousands of Australians come to Oneflare to make connections with the right experts. Have a look at our online reviews to get a better look at what customers think of us'
};
export default ReviewsSection;
