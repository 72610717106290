import { gql } from '@apollo/client';

export const ONEFLARE_REVIEWS = gql`
query oneflareReviews($limit: Int = 8) {
  oneflareReviews(limit: $limit) {
    externalReviewId
    source
    author
    avatarUrl
    rating
    title
    review
    submittedAt
  }
}
`;
